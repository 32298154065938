import { ProductType } from "./PendingApplicationEmailLink/PendingApplicationEmailLinkContent/api";
import { UUID } from "io-ts-types/lib/UUID";
import { lit, type, zero, query } from "fp-ts-routing";
import * as t from "io-ts";
import { optionFromUndefined } from "./globalDomain";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { parseLocation } from "./parseLocation";

interface NotFound {
  readonly _tag: "NotFound";
}

interface EmailVerification {
  readonly _tag: "EmailVerification";
  readonly id: UUID;
}

interface UploadDocumentsLink {
  readonly _tag: "UploadDocumentsLink";
  readonly applicationId: NonEmptyString;
  readonly productType: ProductType;
}

interface UploadDocumentsLinkWithBankerFlow {
  readonly _tag: "UploadDocumentsLinkWithBankerFlow";
  readonly linkId: NonEmptyString;
  readonly productType: ProductType;
}

interface PendingApplicationLink {
  readonly _tag: "PendingApplicationLink";
  readonly applicationId: NonEmptyString;
  readonly productType: ProductType;
}

interface MobileIdUpload {
  readonly _tag: "MobileIdUpload";
  readonly id: UUID;
}

interface MobileProofOfIncomeUpload {
  readonly _tag: "MobileProofOfIncomeUpload";
  readonly id: UUID;
}

interface MobileSelfieCheck {
  readonly _tag: "MobileSelfieCheck";
  readonly id: UUID;
}

interface MobileSelfieTest {
  readonly _tag: "MobileSelfieTest";
}

interface MobileHologramCheck {
  readonly _tag: "MobileHologramCheck";
  readonly id: UUID;
}

interface MobileFullCheck {
  readonly _tag: "MobileFullCheck";
  readonly id: UUID;
}

interface ClientRedirect {
  readonly _tag: "ClientRedirect";
  readonly jwtReference: UUID;
  readonly function: Option<NonEmptyString>;
  readonly step: Option<NonEmptyString>;
  readonly DBNObjectId: Option<NonEmptyString>;
  readonly source: Option<NonEmptyString>;
}

interface UserNotClientOwner {
  readonly _tag: "UserNotClientOwner";
  readonly source: Option<NonEmptyString>;
}

interface RemoteOnboarding {
  readonly _tag: "RemoteOnboarding";
  readonly product: string;
  readonly promo: Option<NonEmptyString>;
  readonly "calculator-data": Option<NonEmptyString>;
}

interface MobileCreateProfile {
  readonly _tag: "MobileCreateProfile";
  readonly id: UUID;
}

interface MobileCreateProfileAndSign {
  readonly _tag: "MobileCreateProfileAndSign";
  readonly id: UUID;
}

interface OpenApplication {
  readonly _tag: "OpenApplication";
  readonly encryptedApplicationId: NonEmptyString;
}

export type InternetLocation =
  | NotFound
  | EmailVerification
  | PendingApplicationLink
  | UploadDocumentsLink
  | UploadDocumentsLinkWithBankerFlow
  | MobileCreateProfile
  | MobileCreateProfileAndSign
  | MobileIdUpload
  | MobileProofOfIncomeUpload
  | MobileSelfieCheck
  | MobileSelfieTest
  | MobileHologramCheck
  | MobileFullCheck
  | ClientRedirect
  | RemoteOnboarding
  | OpenApplication
  | UserNotClientOwner;

export const notFound: InternetLocation = { _tag: "NotFound" };

export const emailVerification = (
  params: Omit<EmailVerification, "_tag">
): InternetLocation => ({
  ...params,
  _tag: "EmailVerification",
});

export const pendingApplicationLink = (
  params: Omit<PendingApplicationLink, "_tag">
): InternetLocation => ({
  ...params,
  _tag: "PendingApplicationLink",
});

export const uploadDocumentsLink = (
  params: Omit<UploadDocumentsLink, "_tag">
): InternetLocation => ({
  ...params,
  _tag: "UploadDocumentsLink",
});

export const uploadDocumentsLinkWithBankerFlowLink = (
  params: Omit<UploadDocumentsLinkWithBankerFlow, "_tag">
): InternetLocation => ({
  ...params,
  linkId: params.linkId,
  _tag: "UploadDocumentsLinkWithBankerFlow",
});

export const mobileIdUpload = (
  params: Omit<MobileIdUpload, "_tag">
): InternetLocation => ({
  ...params,
  _tag: "MobileIdUpload",
});

export const mobileProofOfIncomeUpload = (
  params: Omit<MobileProofOfIncomeUpload, "_tag">
): InternetLocation => ({
  ...params,
  _tag: "MobileProofOfIncomeUpload",
});

export const mobileSelfieCheck = (
  params: Omit<MobileSelfieCheck, "_tag">
): InternetLocation => ({
  ...params,
  _tag: "MobileSelfieCheck",
});
export const mobileSelfieTest = (
  params: Omit<MobileSelfieTest, "_tag">
): InternetLocation => ({
  ...params,
  _tag: "MobileSelfieTest",
});

export const mobileHologramCheck = (
  params: Omit<MobileHologramCheck, "_tag">
): InternetLocation => ({
  ...params,
  _tag: "MobileHologramCheck",
});

export const mobileFullCheck = (
  params: Omit<MobileFullCheck, "_tag">
): InternetLocation => ({
  ...params,
  _tag: "MobileFullCheck",
});

export const mobileCreateProfile = (
  params: Omit<MobileCreateProfile, "_tag">
): InternetLocation => ({
  ...params,
  _tag: "MobileCreateProfile",
});

export const mobileCreateProfileAndSign = (
  params: Omit<MobileCreateProfileAndSign, "_tag">
): InternetLocation => ({
  ...params,
  _tag: "MobileCreateProfileAndSign",
});

export const clientRedirect = (
  params: Omit<ClientRedirect, "_tag">
): InternetLocation => ({
  ...params,
  _tag: "ClientRedirect",
});

export const userNotClientOwner = (
  params: Omit<UserNotClientOwner, "_tag">
): InternetLocation => ({
  ...params,
  _tag: "UserNotClientOwner",
});

export const remoteOnboarding = (
  params: Omit<RemoteOnboarding, "_tag">
): InternetLocation => ({
  ...params,
  _tag: "RemoteOnboarding",
});

export const openApplication = (
  params: Omit<OpenApplication, "_tag">
): InternetLocation => ({
  ...params,
  _tag: "OpenApplication",
});

export function foldInternetLocation<R>(
  matches: {
    [L in InternetLocation["_tag"]]: (
      args: Omit<Extract<InternetLocation, { _tag: L }>, "_tag">
    ) => R;
  }
): (location: InternetLocation) => R {
  return location => matches[location._tag](location as any);
}

const emailVerificationMatch = lit("emailverification").then(type("id", UUID));
const pendingApplicationMatch = lit("pendingapplication").then(
  query(
    t.type({
      applicationId: NonEmptyString,
      productType: ProductType,
    })
  )
);

const uploadDocumentsMatch = lit("uploaddocumentsclient").then(
  query(
    t.type({
      applicationId: NonEmptyString,
      productType: ProductType,
    })
  )
);

const uploadDocumentsLinkWithBankerFlowMatch = lit(
  "uploaddocumentsclient"
).then(
  query(
    t.type({
      linkId: NonEmptyString,
      productType: ProductType,
    })
  )
);

const mobileIdUploadMatch = lit("mobileidupload").then(type("id", UUID));
const mobileProofOfIncomeUploadMatch = lit("mobileproofofincomeupload").then(
  type("id", UUID)
);
const mobileSelfieCheckMatch = lit("mobileselfiecheck").then(type("id", UUID));
const mobileSelfieTestMatch = lit("mobileselfietest");
const mobileHologramCheckMatch = lit("mobilehologramcheck").then(
  type("id", UUID)
);
const mobileFullCheckMatch = lit("mobilefullcheck").then(type("id", UUID));
const mobileCreateProfileMatch = lit("mobilecreateprofile").then(
  type("id", UUID)
);
const mobileCreateProfileAndSignMatch = lit("mobilecreateprofileandsign").then(
  type("id", UUID)
);
const clientRedirectMatch = lit("clientRedirect").then(
  query(
    t.type({
      jwtReference: UUID,
      function: optionFromUndefined(NonEmptyString),
      DBNObjectId: optionFromUndefined(NonEmptyString),
      step: optionFromUndefined(NonEmptyString),
      source: optionFromUndefined(NonEmptyString),
    })
  )
);
const userNotClientOwnerMatch = lit("userNotClientOwner").then(
  query(
    t.type({
      source: optionFromUndefined(NonEmptyString),
    })
  )
);
const remoteOnboardingMatch = lit("onboarding").then(
  query(
    t.type({
      product: t.string,
      promo: optionFromUndefined(NonEmptyString),
      "calculator-data": optionFromUndefined(NonEmptyString),
    })
  )
);
const openApplicationMatch = lit("openapplication").then(
  type("encryptedApplicationId", NonEmptyString)
);

const router = zero<InternetLocation>()
  .alt(emailVerificationMatch.parser.map(emailVerification))
  .alt(pendingApplicationMatch.parser.map(pendingApplicationLink))
  .alt(uploadDocumentsMatch.parser.map(uploadDocumentsLink))
  .alt(
    uploadDocumentsLinkWithBankerFlowMatch.parser.map(
      uploadDocumentsLinkWithBankerFlowLink
    )
  )
  .alt(mobileCreateProfileMatch.parser.map(mobileCreateProfile))
  .alt(mobileCreateProfileAndSignMatch.parser.map(mobileCreateProfileAndSign))
  .alt(mobileIdUploadMatch.parser.map(mobileIdUpload))
  .alt(mobileProofOfIncomeUploadMatch.parser.map(mobileProofOfIncomeUpload))
  .alt(mobileSelfieCheckMatch.parser.map(mobileSelfieCheck))
  .alt(mobileSelfieTestMatch.parser.map(mobileSelfieTest))
  .alt(mobileHologramCheckMatch.parser.map(mobileHologramCheck))
  .alt(mobileFullCheckMatch.parser.map(mobileFullCheck))
  .alt(clientRedirectMatch.parser.map(clientRedirect))
  .alt(remoteOnboardingMatch.parser.map(remoteOnboarding))
  .alt(openApplicationMatch.parser.map(openApplication))
  .alt(userNotClientOwnerMatch.parser.map(userNotClientOwner));
type Props = {
  render: (location: InternetLocation) => JSX.Element;
};

export function InternetRouter(props: Props) {
  const location = parseLocation(
    window.location.pathname + window.location.search,
    router,
    notFound
  );

  return props.render(location);
}
